import React, { useState, useEffect } from "react" //Component,
import chatWindowStyles from '../sass/components/chatwindow.module.scss'	

import { ChatWindow, ChatWindowHead } from "./ChatWindow"

// import cl from "../utils/cl"

const cwS = chatWindowStyles


const ChatShowHide = ({pathCustom}) => {

	const [childVisible, toggleChildVisible] = useState(false)
	const toggleVisible = () => {
		toggleChildVisible(!childVisible)
	}

	const flagHandler = () => {
		setTimeout(() => {
			if ( pathCustom === '/' ) {
				toggleChildVisible(!childVisible)
				// cl(pathname)
			}
		}
		, 4000)
	}

	useEffect(() => {
		console.log("useEffect ran, with 'flagHandler' ")
		flagHandler(4000)
	}, [])


	return (
		<div>
		{!childVisible ? (
			<div onClick={toggleVisible}>
				<ChatWindowHead />
			</div>) : null }
			{childVisible ? (
				<div>
					<div className={cwS.chatWindowExit} onClick={toggleVisible}>
						<div className={cwS.chatWindowExitCross}></div>
					</div>
					<ChatWindow toggleVisible={toggleVisible} />
				</div>
			) : null}
		</div>
	)
}

export default ChatShowHide
