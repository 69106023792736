// File: frontend/src/services/api.js

// Test routes  
// import cl from "../utils/cl"
const apiUrl = 'https://cors-anywhere-cq.herokuapp.com/https://varcamdobb-api.herokuapp.com/'
// const apiUrl = 'http://localhost:5000/'


export const sendEmail = (messageClient, email, mobile, location) => {
  // xport const sendEmail = (email, name, mobile, location) => {
  // cl([email, name, mobile, location])

  return fetch(`${apiUrl}/api/send_email`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ messageClient, email, mobile, location })
    // body: JSON.stringify({ email, name, mobile, location })
  }).then(response => response.json());
};

