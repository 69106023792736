// import React from 'react'
import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'

// import cl from '../utils/cl'

import headerStyles from '../sass/components/header.module.scss'
// import WaveformSvg from '../assets/icons/waveform.svg'
const hS = headerStyles


const Header = () => {

// Show/hide menu mobile
const [childVisible, toggleChildVisible] = useState(false)

const onClick = () => {
  toggleChildVisible(!childVisible)
  // cl('Menu icon clicked')
  // setTimeout(() => {
  //       toggleChildVisible(!childVisible)
  //       cl('helloooooo')
	// 	}
	// 	, 1000)
}
// Pull from WordPress
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    // <div className="bg-grey">
    <div className={hS.headerWrapper}>
      <header className={hS.header}>
        <div className={hS.titleMenuWrapper}>
          <h1 className={hS.titleWrapper}>
              <Link className={hS.title} to="/">
                {data.site.siteMetadata.title}
              </Link>
          </h1>

          <div className={hS.spaceHeader}></div>

          <div className={hS.menuIconWrapper} onClick={onClick} onKeyDown={onClick} >
          <svg
          className={hS.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
          </div>
        </div>

        {childVisible ? (
        <nav>
          <ul className={hS.navListMobile}>
            <li>
              <Link
                className={hS.navItemMobile}
                activeClassName={hS.activeNavItemMobile}
                to="/"
              >
                Bienvenue
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItemMobile}
                activeClassName={hS.activeNavItemMobile}
                to="/locationlist"
              >
                Locations
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItemMobile}
                activeClassName={hS.activeNavItemMobile}
                to="/blog"
              >
                Blog Découverte
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItemMobile}
                activeClassName={hS.activeNavItemMobile}
                to="/activity"
              >
                Activités
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItemMobile}
                activeClassName={hS.activeNavItemMobile}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        ) : null}

        {/* Have to opimise, but for now ,make menu visible in a container depending on media screen  */}
        <nav className={hS.navListDesktop}>
          <ul className={hS.navList}>
            <li>
              <Link
                className={hS.navItem}
                activeClassName={hS.activeNavItem}
                to="/"
              >
                Bienvenue
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItem}
                activeClassName={hS.activeNavItem}
                to="/locationlist"
              >
                Locations
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItem}
                activeClassName={hS.activeNavItem}
                to="/blog"
              >
                Blog Découverte
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItem}
                activeClassName={hS.activeNavItem}
                to="/activity"
              >
                Activités
              </Link>
            </li>
            <li>
              <Link
                className={hS.navItem}
                activeClassName={hS.activeNavItem}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      
      {/* END// -- Have to opimise, but for now ,make menu visible in a container depending on media screen  */}


      </header>
    </div>
  )
}

export default Header
