import React from 'react'

import Header from './header'
import ContactBar from './contactbar'
import ChatShowHide from './ChatShowHide'
import Footer from './footer'


import layoutStyles from '../sass/components/layout.module.scss'

// import cl from "../utils/cl"

const Layout = props => {

  return (
      <div className={layoutStyles.container}>
        <div className={layoutStyles.content}>
          <Header />
          <ContactBar />
          {props.children}
        </div>
        <Footer />
        <ChatShowHide pathCustom={props.pathCustom} />
      </div>
  )
}

export default Layout

// {childVisible ? (
//   <ChatModal message={props.messageClient} onClick={props.onClick} />
// ) : null}
