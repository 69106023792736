const sunIconAlt =
  'Soleil de Provence, site location de villas de vacances Varcamdobb.'
const houseIconAlt =
  'Location de vacances, site location de villas de vacances Varcamdobb.'
const bigSwimmingPoolIconAlt =
  'Location de vacances avec piscine, site location de villas de vacances Varcamdobb.'
const sunsetIconAlt = "Coucher de soleil sur la Méditerrannée, site location de villas de vacances Varcamdobb."
const mountainIconAlt = "Coucher de soleil sur la Méditerrannée, site location de villas de vacances Varcamdobb."
const umbrellaIconAlt =
  `Parasol sur une plage de la Côte d'Azur, site location de villas de vacances Varcamdobb.`
const bloggerIconAlt = "Blog du site location de villas de vacances Varcamdobb."
const swimmingPoolIconAlt = "Location de vacances avec piscine, site location de villas de vacances Varcamdobb."
const talkIconAlt = "Contactez Varcamdobb, site location de villas de vacances." 

module.exports = {
  sunIconAlt,
  houseIconAlt,
  bigSwimmingPoolIconAlt,
  sunsetIconAlt,
  mountainIconAlt,
  umbrellaIconAlt,
  bloggerIconAlt,
  swimmingPoolIconAlt,
  talkIconAlt
}