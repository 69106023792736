import React, { Component } from 'react'
import Emoji from 'react-emoji-render'
import 'antd/dist/antd.css'

import ChatModal from './ChatModal'
import { Icon } from 'antd'
import chatWindowStyles from '../sass/components/chatwindow.module.scss'
import mailIconSvg from '../assets/icons/mail.svg'
import cqLogo from '../assets/images/cq-logo-1.png'
import { sendEmail } from '../services/api'
// import cl from '../utils/cl'

const cwS = chatWindowStyles

class ChatWindow extends Component {
  state = {
    messageClient: '',
    email: '',
    mobile: '',
    location:
      "Ceci est un message de la chatbox, la location désirée doit être détaillée par l'internaute.",
    modalChildVisible: false,
    messageEnvoi: '',
    messageFillPlease: '',
    windowReduced: false,
  }

  closeChatWindow = () => {
    this.props.toggleVisible()
  }

  // hideChatWindow = () => {
  //   this.setState({ windowReduced: true })
  //   this.setState({ modalChildVisible: false })
  // }

  // showChatWindow = () => {
  //   this.setState({ windowReduced: false })
  //   this.setState({ modalChildVisible: true })
  // }

  handleMsgForm = () => {
    // cl(this.state)
    if (
      (this.state.email !== '' || this.state.mobile !== '') &&
      this.state.messageClient !== ''
    ) {
      // First step after having checked the content: display "Message sending..."
      console.log('Display "Sending"')
      this.setState({
        messageEnvoi: 'Envoi...',
        messageFillPlease: '',
        modalChildVisible: true,
      })

      // Autohide after 1,5sec, in case the email server takes time to display "Thx, message sent"
      // setTimeout( () => {
      //   this.hideChatWindow()
      // }, 1500)

      // Second step : send the email via an api call
      setTimeout(() => {
          sendEmail(
            this.state.messageClient,
            this.state.email,
            this.state.mobile,
            this.state.location
         )
        .then(({ message }) => {
      console.log('Display "Sent"')
        this.setState({ messageClient: message })
        this.setState({
          messageEnvoi: '',
          messageFillPlease: '',
          modalChildVisible: true,
        })

        setTimeout(() => {
          console.log('Close Chatbox now')
          this.closeChatWindow()
        }, 1000 ) // Just wait for the user to read
        })
      }, 1500)
    } else {
      this.setState({
        messageEnvoi: '',
        messageFillPlease:
          'Un email, un numéro de téléphone et un message nous seront nécessaires pour vous joindre.',
      })
      this.setState({ modalChildVisible: true })
      setTimeout(() => {
        console.log('Hide modal now')
        this.setState({
          messageFillPlease: '',
          modalChildVisible: false,
        })
      }, 2000)
    }
  }

  render() {
    return (
      <div
        className={
          this.state.windowReduced
            ? cwS.chatWindowHidden
            : cwS.chatWindowContainer
        }
      >
        <div className={cwS.chatWindowTitle}>
          <div className={cwS.chatWindowTitleMain}>
            <div className={cwS.chatWindowTitleMainText1}></div>
            <img className={cwS.chatWindowMailIcon} src={mailIconSvg} alt="" />
            <div className={cwS.chatWindowTitleMainText2}></div>
            <div className={cwS.chatWindowTitleMainText3}>
              <span className={cwS.chatWindowTitleMainTitle}>
                Envoyer un message
                {/* <span>Send us a messsage on Senda-Chat ! */}
              </span>
            </div>
          </div>
          {/* <div className="chat-window--title-sub">
				subtitle
				</div> */}
        </div>

        <form
          novalidate="novalidate"
          className={cwS.chatWindow}
          onSubmit={e => {
            e.preventDefault()
            // this.props.onMessage(this.textArea.value)
            // this.handleMsgForm(e)
            this.handleMsgForm()
          }}
        >
          <div className={cwS.chatWindowPreamble}>
            <div className={cwS.chatWindowPreambleText}>
              A Customer Support Tool by
            </div>
            <div className={cwS.chatWindowPreambleLogobox}>
              <img
                className={cwS.chatWindowPreambleLogo}
                src={cqLogo}
                alt="Logo Creativity Quarks"
              />
            </div>
          </div>

          <div className={cwS.chatWindowChatInviteMsg}>
            <div className={cwS.chatWindowChatInviteMsgBlock1}></div>
            <div className={cwS.chatWindowChatInviteMsgBlock2}>
              Bonjour ami de la Provence !
              <Emoji text="🙋‍♂️🌳🏞️" /> <br />
              Ecris-nous un message ici, auquel nous répondrons très vite
              <Emoji text="&#128644;" />, appel ou email au choix !
              {/* <Emoji text=":)"/>  */}
            </div>
          </div>

          <div>
            <div className={cwS.chatWindowSeparator}></div>
            <div className={cwS.chatWindowForm}>
              <div className={cwS.chatWindowFormBlock2}>
                <textarea
                  // ref={e => {
                  // 	this.setState({ messageClient : e })
                  // }}
                  onChange={e => {
                    this.setState({ messageClient: e.target.value })
                  }}
                  className={cwS.chatWindowFormBlock2Textarea}
                  placeholder="Vous pouvez formuler un message ici !"
                />

                <input
                  onChange={e => {
                    this.setState({ mobile: e.target.value })
                  }}
                  type="text"
                  className={cwS.chatWindowFormBlock2Mobile}
                  placeholder="Laissez votre numéro de portable..."
                  id="mobile"
                  required
                />

                <input
                  onChange={e => {
                    this.setState({ email: e.target.value })
                  }}
                  type="email"
                  className={cwS.chatWindowFormBlock2Email}
                  placeholder="...et un email ici svp."
                  id="email"
                  required
                />
              </div>
              <div className={cwS.chatWindowFormBlock1}></div>
            </div>
          </div>

          <button className={cwS.chatWindowButton}> Envoyer ! </button>
        </form>

        <div className={cwS.chatWindowInfo}>
          <p className={cwS.chatWindowInfoText}>
            Information à propos des termes d'utilisation.
          </p>
        </div>
        {this.state.modalChildVisible ? (
          <ChatModal
            messageEnvoi={this.state.messageEnvoi}
            messageFillPlease={this.state.messageFillPlease}
          />
        ) : null}
      </div>
    )
  }
}

class ChatWindowHead extends Component {
  render() {
    return (
      <div className={cwS.chatWindowContainerHead}>
        <div className={cwS.chatWindowTitle}>
          <div className={cwS.chatWindowTitleMain}>
            <div className={cwS.chatWindowTitleMainText1}></div>
            <img className={cwS.chatWindowMailIcon} src={mailIconSvg} alt="" />
            <div className={cwS.chatWindowTitleMainText2}></div>
            <div className={cwS.chatWindowTitleMainText3}>
              <span className={cwS.chatWindowTitleMainTitle}>
                Envoyer un message
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { ChatWindow, ChatWindowHead }
